import Vue from 'vue';
import VueRouter from 'vue-router';
import MainPage from '../views/Firebase/MainPage.vue';
import links from './links';

Vue.use(VueRouter);

const DefaultTitle = process.env.VUE_APP_SITE_TITLE;

const routes = [
    {
        path: '/',
        name: 'home',
        component: MainPage,
    },
    {
        path: '/main-page',
        name: 'main-page',
        component: MainPage
    },
    {
        path: '/stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "stories" */ '../views/Firebase/Stories.vue'),
    },
    {
        path: '/currencies',
        name: 'currencies',
        component: () => import(/* webpackChunkName: "currencies" */ '../views/Firebase/Currencies.vue'),
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/Firebase/Categories.vue'),
    },
    {
        path: '/genres',
        name: 'genres',
        component: () => import(/* webpackChunkName: "genres" */ '../views/Firebase/Genres.vue'),
    },
    {
        path: '/story',
        name: 'story',
        props: true,
        component: () => import(/* webpackChunkName: "story" */ '../views/Firebase/Story.vue'),
        children: [
            {
                path: ':storyId',
                name: 'story-view',
                component: import(/* webpackChunkName: "story" */ '../views/Firebase/Story.vue'),
            },
        ]
    },
    {
        path: '/chapters',
        name: 'chapters',
        component: () => import(/* webpackChunkName: "chapters" */ '../views/Firebase/Chapter.vue'),
        props: true,
    },
    {
        path: '/first-story',
        name: 'first-story',
        component: () => import(/* webpackChunkName: "first_story" */ '../views/Firebase/FirstStory.vue'),
        props: true,
    },
    {
        path: '/economy',
        name: 'economy',
        component: () => import(/* webpackChunkName: "economy" */ '../views/Firebase/Economy.vue'),
        props: true,
    },
    {
        path: '/prices',
        name: 'prices',
        component: () => import(/* webpackChunkName: "prices" */ '../views/Firebase/Prices.vue'),
        props: true,
    },
    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/Firebase/Products.vue'),
        props: true,
    },
    {
        path: '/recommended-stories',
        name: 'recommended-stories',
        component: () => import(/* webpackChunkName: "products" */ '../views/Firebase/RecommendedStories.vue'),
        props: true,
    },
    {
        path: '/keys-timer',
        name: 'keys-timer',
        component: () => import(/* webpackChunkName: "keys_timer" */ '../views/Firebase/KeysTimer'),
        props: true,
    },
    {
        path: '/daily-bonus',
        name: 'daily-bonus',
        component: () => import(/* webpackChunkName: "daily_bonus" */ '../views/Firebase/DailyBonus'),
        props: true,
    },
    {
        path: '/fake-stories',
        name: 'fake-stories',
        component: () => import(/* webpackChunkName: "fake_stories" */ '../views/Firebase/FakeStories'),
        props: true,
    },
    {
        path: '/default-user',
        name: 'default-user',
        component: () => import(/* webpackChunkName: "default_user" */ '../views/Firebase/DefaultUser'),
        props: true,
    },
    {
        path: '/players',
        name: 'players',
        component: () => import(/* webpackChunkName: "players" */ '../views/Firebase/Players'),
        props: true,
    },
    {
        path: '/season-first-purchase',
        name: 'season-first-purchase',
        component: () => import(/* webpackChunkName: "season_first_purchase" */ '../views/Backend/SeasonFirstPurchase'),
        props: true,
    },
    {
        path: '/sale-action',
        name: 'sale-action',
        component: () => import(/* webpackChunkName: "sale_action" */ '../views/Backend/SaleAction'),
        props: true,
    },
    {
        path: '/app-module',
        name: 'app-module',
        component: () => import(/* webpackChunkName: "app_module" */ '../views/Firebase/AppModule'),
        props: true,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        const config = links.find(config => config.link === to.name);
        document.title = config ? `Админка - ${config.title}` : DefaultTitle;
        
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    });
});

export default router;
