import Vue from 'vue';
import Vuex from 'vuex';
import firebase from '@/store/modules/firebase';
import createPersistedState from 'vuex-persistedstate';
import {update, UPDATE} from '@/store/utils';

Vue.use(Vuex);

const state = {
    routerHasHistory: false,
};

const getters = {};

const mutations = {
    UPDATE,
};

const actions = {
    update,
};

const modules = {
    firebase
};

const store = new Vuex.Store({
    namespaced: true,
    name: 'store',
    state,
    getters,
    actions,
    mutations,
    modules,
    plugins: [createPersistedState({
        paths: [
            'firebase.chaptersSortingOrder',
        ],
    })],
});

if (module.hot) {
    module.hot.accept([
        './modules/firebase',
    ], () => {
        const newFirebaseModule = require('./modules/firebase').default;
        
        store.hotUpdate({
            modules: {
                firebase: newFirebaseModule,
            },
        });
    });
}

export default store;
