<template>
    <div class="main-page page">
        <div class="main-page__content">
            Загрузка...
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'main-page',
    data: () => ({}),
    components: {
        UiButton: () => import('../../components/ui/UiButton')
    },
    async mounted() {
        const response = await this.getConfig();
        if (!response.error) {
            await this.$router.push('/stories');
        } else {
            alert('Ошибка авторизации в гугле');
        }
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateConfigs'])
    }
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.main-page {

}
</style>
