import {clone, sortDesc} from '@/utils';
import Vue from 'vue';

const getters = {
    getDefaultStoryReward: state => {
        return [{
            currency_id: state.config[`EconomyConfig${state.environment}`].story_reward_currency_id,
            value: state.config[`EconomyConfig${state.environment}`].story_reward_currency_value
        }];
    },
    getNewStoryID: state => {
        const idsDesc = state.config[`Stories${state.envPath}`].stories.map(data => data.id).sort(sortDesc);
        return idsDesc[0] + 1;
    },
    getNewChapterID: state => {
        const allChaptersIds = [];
        state.config[`Stories${state.envPath}`].stories.forEach(storyData => {
            storyData.chapters.forEach(chapterData => allChaptersIds.push(chapterData.id));
        });
        return allChaptersIds.sort(sortDesc)[0] + 1;
    },
    getStoryById: state => storyId => state.config[`Stories${state.envPath}`].stories.find(story => story.id === storyId),
    getStoryChapters: state => storyId => {
        const storyConfig = state.config[`Stories${state.envPath}`].stories.find(story => story.id === parseInt(storyId, 10));
        return storyConfig ? storyConfig.chapters : [];
    },
    getChapterById: state => (storyId, chapterId) => {
        const story = state.config[`Stories${state.envPath}`].stories.find(story => story.id === storyId);
        
        if (story) {
            const chapter = story.chapters.find(chapter => chapter.id === chapterId);
            return chapter || false;
        }
        
        return false;
    },
    getStoryCategories: state => storyId => {
        const storyConfig = state.config[`Stories${state.envPath}`].stories.find(story => story.id === parseInt(storyId, 10));
        const storyCategories = storyConfig ? storyConfig.categories : [];
        let categoriesData = [];
        
        storyCategories.forEach(categoryData => {
            const config = state.config[`Stories${state.envPath}`].categories.find(category => category.id === categoryData.id);
            if (config) {
                categoriesData.push({
                    id: config.id,
                    name: config.name
                });
            }
        });
        
        return categoriesData;
    },
    getStoryGenres: state => (storiesModule, storyId) => {
        return [];
    },
    getNextChapterOrder: state => storyId => {
        const story = state.config[`Stories${state.envPath}`].stories.find(story => story.id === storyId);
        let order = 0;
        
        if (story && story.chapters && story.chapters.length) {
            story.chapters.forEach(chapter => {
                if (chapter.order > order) order = chapter.order;
            });
        }
        return order + 1;
    },
};
const actions = {
    async saveStory({commit, dispatch}, {storyData, isNewStory}) {
        return new Promise(resolve => {
            commit('SAVE_STORY', storyData);
            dispatch('updateConfig')
                .then(response => {
                    if (isNewStory)
                        resolve({...response, redirectStory: storyData.id});
                    else
                        resolve(response);
                })
                .catch(error => resolve(error));
        });
    },
    async setStoryChapterPublishState({commit, dispatch}, data) {
        return new Promise(resolve => {
            commit('SET_STORY_CHAPTER_PUBLISH_STATE', data);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async saveStoryChapter({commit, dispatch}, {storyId, chapterData}) {
        return new Promise(resolve => {
            commit('SAVE_STORY_CHAPTER', {storyId, chapterData});
            commit('ADD_CHAPTER_SORTING_ORDER', {storyId, chapterId: chapterData.id, commit});
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async updateChapterSortingOrder({commit, dispatch}, data) {
        return new Promise(resolve => {
            commit('UPDATE_CHAPTERS_SORTING_ORDER', data);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async updateStoriesRecommendedOrder({commit, dispatch}, sortOrder) {
        return new Promise(resolve => {
            commit('UPDATE_STORIES_RECOMMENDED_ORDER', sortOrder);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async updateFakeStories({commit, dispatch}, data) {
        return new Promise(resolve => {
            commit('UPDATE_ECONOMY_CONFIG', data);
            commit('UPDATE_FAKE_STORIES', data);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
};
const mutations = {
    SET_STORY_CHAPTER_PUBLISH_STATE(state, {storyId, chapterId, isPublished}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        const chapterIndex = stories[storyIndex].chapters.findIndex(chapter => chapter.id === chapterId);
        if (chapterIndex === -1) return;
        stories[storyIndex].chapters[chapterIndex].is_published = isPublished;
        
        // проверяем опубликованность истории - установлена последняя глава и истории и она должна быть  опубликована
        const lastPublishedChapterId = stories[storyIndex].last_chapter_id;
        const chapterData = stories[storyIndex].chapters.find(chapter => chapter.id === lastPublishedChapterId);
        stories[storyIndex].is_published = typeof chapterData !== 'undefined';
        
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    ADD_STORY_GENRE(state, {storyId, genreData}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        const genreIndex = stories[storyIndex].genres.findIndex(g => g.id === genreData.id);
        if (genreIndex > -1) return;
        
        stories[storyIndex].genres.push(genreData);
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    SAVE_STORY(state, storyData) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyData.id);
        if (storyIndex > -1) stories[storyIndex] = storyData;
        else stories.push(storyData);
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    SAVE_STORY_CHAPTER(state, {storyId, chapterData}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        const chapterIndex = stories[storyIndex].chapters.findIndex(chapter => chapter.id === chapterData.id);
        if (chapterIndex > -1)
            Object.keys(chapterData).forEach(key => stories[storyIndex].chapters[chapterIndex][key] = chapterData[key]);
        else
            stories[storyIndex].chapters.push(chapterData);
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    ADD_STORY_TO_CATEGORY(state, {storyId, categoryId}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return; // создается новая история
        //todo разобраться с order
        stories[storyIndex].categories.push({id: categoryId, order: 0});
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
        
        const categories = clone(state.config[`Stories${state.environment}`].categories);
        const categoryIndex = categories.findIndex(category => category.id === categoryId);
        if (categoryIndex === -1) return;
        categories[categoryIndex].stories.push(storyId);
        Vue.set(state.config[`Stories${state.envPath}`], 'categories', categories);
    },
    DELETE_STORY_FROM_CATEGORY(state, {storyId, categoryId}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        let storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return; // создается новая история
        let categoryIndex = stories[storyIndex].categories.findIndex(category => category.id === categoryId);
        if (categoryIndex === -1) return;
        stories[storyIndex].categories.splice(categoryIndex, 1);
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
        
        const categories = clone(state.config[`Stories${state.environment}`].categories);
        categoryIndex = categories.findIndex(category => category.id === categoryId);
        if (categoryIndex === -1) return;
        storyIndex = categories[categoryIndex].stories.indexOf(storyId);
        if (storyIndex === -1) return;
        categories[categoryIndex].stories.splice(storyIndex, 1);
        Vue.set(state.config[`Stories${state.envPath}`], 'categories', categories);
    },
    DELETE_STORY_GENRE(state, {storyId, genreId}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        const genreIndex = stories[storyIndex].genres.findIndex(genre => genre.id === genreId);
        if (genreIndex === -1) return;
        
        stories[storyIndex].genres.splice(genreIndex, 1);
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    SET_CHAPTER_DEFAULT_REWARD(state, {storyId, chapterId}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        const chapterIndex = stories[storyIndex].chapters.findIndex(chapter => chapter.id === chapterId);
        if (chapterIndex === -1) return;
        
        stories[storyIndex].chapters[chapterIndex].rewards = [{
            currency_id: state.config[`EconomyConfig${state.environment}`].chapter_reward_currency_id,
            value: state.config[`EconomyConfig${state.environment}`].chapter_reward_currency_value,
        }];
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
    UPDATE_CHAPTERS_SORTING_ORDER(state, {storyId, order}) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        const storyIndex = stories.findIndex(story => story.id === storyId);
        if (storyIndex === -1) return;
        
        order.forEach((chapterId, index) => {
            const chapterIndex = stories[storyIndex].chapters.findIndex(chapterData => chapterData.id === chapterId);
            if (chapterIndex > -1) stories[storyIndex].chapters[chapterIndex].order = index + 1;
        });
        
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
        Vue.set(state.chaptersSortingOrder, storyId, order);
    },
    ADD_CHAPTER_SORTING_ORDER(state, {storyId, chapterId, commit}) {
        const order = state.chaptersSortingOrder[storyId] ? clone(state.chaptersSortingOrder[storyId]) : [];
        if (order.includes(chapterId)) return;
        order.push(chapterId);
        commit('UPDATE_CHAPTERS_SORTING_ORDER', {storyId, order});
    },
    UPDATE_FAKE_STORIES(state, data) {
        if (data.fake_stories_enabled)
            Vue.set(state.config[`Stories${state.envPath}`].fake_stories, 'enabled', data.fake_stories_enabled);
        if (data.fake_stories_chapters_count)
            Vue.set(state.config[`Stories${state.envPath}`].fake_stories, 'chapters_count', data.fake_stories_chapters_count);
    },
    UPDATE_STORIES_RECOMMENDED_ORDER(state, sortOrder) {
        const stories = clone(state.config[`Stories${state.envPath}`].stories);
        Object.entries(sortOrder).forEach(([storyId, order]) => {
            storyId = parseInt(storyId, 10);
            const storyIndex = stories.findIndex(story => story.id === storyId);
            if (storyId > -1) stories[storyIndex].order_recommended = order;
        });
        Vue.set(state.config[`Stories${state.envPath}`], 'stories', stories);
    },
};

export default {
    getters,
    actions,
    mutations
}
