export default [
    /*{
        title: 'Валюта',
        link: '/currencies',
	    disabled: true,
    },
    {
        title: 'Стартовые истории',
        link: '/first-story',
	    disabled: true,
    },
    {
        title: 'Фейковые истории',
        link: '/fake-stories',
	    disabled: true,
    },
    {
        title: 'Экономика',
        link: '/economy',
	    disabled: true,
    },
    {
        title: 'Рекомендованные истории',
        link: '/recommended-stories',
	    disabled: true,
    },
	{
		title: 'Таймер ключей',
		link: '/keys-timer',
		disabled: true,
	},
	{
		title: 'Игроки',
		link: '/players',
		disabled: true,
	},
	*/
    {
        title: 'Stories',
        link: '/stories',
    },
    {
        title: 'Categories',
        link: '/categories',
    },
    {
        title: 'Prices',
        link: '/prices',
    },
    {
        title: 'Products',
        link: '/products',
    },
    {
        title: 'DailyBonuses',
        link: '/daily-bonus',
    },
    {
        title: 'Users',
        link: '/default-user',
    },
    {
        title: 'App Module',
        link: '/app-module',
    },
];
