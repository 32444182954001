import Vue from 'vue';
import axios from 'axios';

import * as stories from '@/store/submodules/stories';
import * as products from '@/store/submodules/products';
import configGetters from '@/store/submodules/configGetters';

import currencies from '@/configs/currencies';

import { UPDATE, update } from '../utils';
import credentials from '@/json/google-services.json';

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';
import { clone, sortDesc } from '@/utils';
import bus from '@/plugins/bus';

const FirebaseConfig = {
    apiKey: credentials.client[0].api_key[0].current_key,
    authDomain: `${credentials.project_info.project_id}.firebaseapp.com`,
    databaseURL: credentials.project_info.firebase_url,
    projectId: credentials.project_info.project_id,
    storageBucket: credentials.project_info.storage_bucket,
    appId: '1:1047356254397:android:517a1e9706b5a2042aefd4',
};
const FetchIntervalMillis = 1000;
const ConfigFieldsToCopy = [
    'AppModule',
    'CatalogModule',
    'DailyBonuses',
    'Prices',
    'Products',
    'StoreModule',
    'Stories',
    'Users',
];
const DatabaseCollectionsToDrop = [
    'Chapters',
    'Characters',
    'Customizations',
    'Nodes',
    'Stories',
    'Users',
];

const state = {
    environment: '',
    envVersion: '',
    envPath: '',
    userEmail: '',
    isInitialized: false,
    accessToken: '',
    chaptersSortingOrder: {},
    config: {},
    requestPending: false,
    environments: {},
};

const getters = {
    ...stories.default.getters,
    ...products.default.getters,
    ...configGetters,
    requestConfig: state => {
        return {
            withCredentials: true,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.accessToken}`,
            },
        };
    },
    getNewEntityID: state => entityName => {
        if (!state.config.hasOwnProperty(entityName + state.environment)) {
            alert('FirebaseModule::getNewEntityID Invalid entity name');
            return 0;
        }
        const idsDesc = state.config[entityName + state.environment].map(data => data.id).sort(sortDesc);
        return idsDesc[0] + 1;
    },
    getNewPriceID: state => {
        const idsDesc = state.config[`Prices${state.envPath}`].prices.map(price => price.id).sort(sortDesc);
        return idsDesc[0] + 1;
    },
    getGenreById: state => id => state.config[`Genres${state.environment}`].find(genre => genre.id === id),
    getCategoryById: state => id => state.config[`Categories${state.environment}`].find(category => category.id === id),
    currenciesOptions: _ => Object.entries(currencies).map(([id, name]) => ({ name, value: id })),
    defaultUserConfig: state => state.config[`Users${state.envPath}`],
    getCurrencyName: _ => id => currencies[id],
};

const actions = {
    ...stories.default.actions,
    ...products.default.actions,
    
    update,
    async getConfig({ state, commit, dispatch }) {
        return new Promise(resolve => {
            if (state.requestPending) {
                resolve({ error: 'Request config is pending' });
                return;
            }
            
            if (state.isInitialized) {
                resolve(state.config);
                return;
            }
            
            const firebaseApp = initializeApp(FirebaseConfig);
            const auth = getAuth();
            const provider = new GoogleAuthProvider().addScope('https://www.googleapis.com/auth/cloud-platform');
            
            commit('UPDATE', { requestPending: true });
            
            signInWithPopup(auth, provider)
                .then(result => {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    commit('UPDATE', { accessToken: credential.accessToken, userEmail: result.user.email });
                    
                    const remoteConfig = getRemoteConfig(firebaseApp);
                    remoteConfig.settings.minimumFetchIntervalMillis = FetchIntervalMillis;
                    remoteConfig.settings.fetchTimeoutMillis = FetchIntervalMillis;
                    
                    fetchAndActivate(remoteConfig).then(_ => {
                        const config = getAll(remoteConfig);
                        Object.keys(config).forEach(key => {
                            try {
                                config[key] = JSON.parse(config[key].asString());
                            } catch (e) {
                            }
                        });
                        commit('UPDATE', {
                            requestPending: false,
                            isInitialized: true,
                            config,
                        });
                        dispatch('parseEnvironments');
                        resolve(config);
                    }).catch(error => {
                        commit('UPDATE', { requestPending: false });
                        resolve({ error });
                    });
                })
                .catch(error => {
                    commit('UPDATE', { requestPending: false });
                    resolve({ error });
                });
        });
    },
    parseEnvironments({ state, commit }) {
        const envs = {};
        
        Object.keys(state.config).forEach(key => {
            const split = key.split('_');
            const env = split[1] || 'Default';
            const version = split.splice(2).join('.') || '0.0.0';
            
            if (!envs.hasOwnProperty(env))
                envs[env] = [];
            
            if (!envs[env].includes(version))
                envs[env].push(version);
        });
        commit('SET_ENVIRONMENTS', envs);
        commit('SET_ENVIRONMENT', { env: 'Default', version: '0.0.0', useDefault: true });
    },
    async updateConfig({ state, dispatch }, params = {}) {
        return new Promise(resolve => {
            // все же знают, что безопасно хранить пароли в открытом виде строками, да?
            if (!params.forceUpdate && !state.environment.length && prompt('Введите код подтверждения') !== 'allowmetouse') {
                resolve({ error: 'Нет прав редактировать прод конфиг' });
                return;
            }
            const config = { parameters: {} };
            
            Object.keys(state.config).forEach(key => {
                let value;
                let valueType;
                
                if (typeof state.config[key] == 'object') {
                    value = JSON.stringify(state.config[key]);
                    valueType = 'JSON';
                } else {
                    value = state.config[key];
                    switch (typeof state.config[key]) {
                        case 'number':
                            valueType = 'Number';
                            break;
                        case 'boolean':
                            valueType = 'Boolean';
                            break;
                        default:
                            valueType = 'String';
                    }
                }
                
                config.parameters[key] = { defaultValue: { value }, valueType };
            });
            dispatch('putConfigToFirebase', config)
                .then(_ => resolve({ success: true }))
                .catch(error => resolve({ error }));
        });
    },
    async copyConfigs({ state, commit, dispatch }, { sourceName, destinationName }) {
        return new Promise(resolve => {
            if (!state.config[`${ConfigFieldsToCopy[0] + sourceName}`]) {
                resolve({ error: `Тип конфига "${sourceName || 'Default'}" не найден` });
                return;
            }
            
            if (!state.config[`${ConfigFieldsToCopy[0] + destinationName}`]) {
                resolve({ error: `Тип конфига "${destinationName || 'Default'}" не найден` });
                return;
            }
            
            commit('COPY_CONFIGS', { sourceName, destinationName });
            dispatch('updateConfig', { forceUpdate: true })
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async putConfigToFirebase({ state, getters, dispatch }, params) {
        return new Promise((resolve, reject) => {
            const config = getters.requestConfig;
            config.headers['If-Match'] = '*';
            
            axios.put('https://firebaseremoteconfig.googleapis.com/v1/projects/lovesaga/remoteConfig', params, getters.requestConfig)
                .then((response) => {
                    const { data } = response;
                    resolve(data);
                })
                .catch(error => reject(error));
        });
    },
    async getDatabaseUser({ state, getters }, userId) {
        return new Promise(resolve => {
            let dbUrl = FirebaseConfig.databaseURL;
            
            if (state.environment !== 'Default')
                dbUrl = dbUrl.replace('lovesaga-default-rtdb', `lovesaga-${state.environment.toLowerCase()}`);
            
            axios.get(`${dbUrl}/Users/${userId}.json`, getters.requestConfig)
                .then(response => {
                    if (response.status !== 200)
                        resolve({ error: 'Ошибка поиска. Статус не 200' });
                    else if (!response.data)
                        resolve({ error: 'Игрок не найден' });
                    else
                        resolve(Object.values(response.data)[0]);
                })
                .catch(error => resolve({ error }));
        });
    },
    async dropDatabaseUser({ state, getters }, userId) {
        return new Promise(resolve => {
            const promises = DatabaseCollectionsToDrop
                .map(collectionName => axios.delete(`${FirebaseConfig.databaseURL}/${collectionName}/${userId}.json`, getters.requestConfig));
            
            Promise.all(promises)
                .then(response => {
                    if (response.reduce((a, b) => a && b.status === 200, true)) {
                        resolve({ success: 1 });
                    } else {
                        resolve({ error: 'Какой-то из статусов пришел не 200, стоит проверить конфиг в фаербазе' });
                    }
                })
                .catch(error => resolve({ error }));
        });
    },
    async signOut({ commit }) {
        return new Promise(resolve => {
            const auth = getAuth();
            signOut(auth)
                .then(response => {
                    commit('UPDATE', { userEmail: '', accessToken: '', isInitialized: false });
                    resolve(response);
                })
                .catch(error => resolve(error));
        });
    },
    
    async saveCurrency({ commit, dispatch }, currencyData) {
        return new Promise(resolve => {
            commit('SAVE_CURRENCY', currencyData);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async saveCategory({ commit, dispatch }, categoryData) {
        return new Promise(resolve => {
            commit('SAVE_CATEGORY', categoryData);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async saveGenre({ commit, dispatch }, genreData) {
        return new Promise(resolve => {
            commit('SAVE_GENRE', genreData);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async savePrice({ commit, dispatch }, priceData) {
        return new Promise(resolve => {
            commit('SAVE_PRICE', priceData);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async updateEconomyConfig({ commit, dispatch }, data) {
        return new Promise(resolve => {
            commit('UPDATE_ECONOMY_CONFIG', data);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async updateDailyBonusesConfig({ commit, dispatch }, config) {
        return new Promise(resolve => {
            commit('UPDATE_DAILY_BONUSES_CONFIG', config);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async saveDefaultUserConfig({ commit, dispatch }, config) {
        return new Promise(resolve => {
            commit('SET_DEFAULT_USER_CONFIG', config);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async saveAppModuleConfig({ commit, dispatch }, config) {
        return new Promise(resolve => {
            commit('SET_APP_MODULE_CONFIG', config);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
};

const mutations = {
    ...stories.default.mutations,
    ...products.default.mutations,
    
    UPDATE,
    SET_ENVIRONMENT(state, { env, version, useDefault = false }) {
        if (!state.environments[env]?.includes(version) && !useDefault)
            return;
        
        state.environment = env;
        state.envVersion = version;
        
        let envPath = env === 'Default' ? '' : `_${env}`;
        envPath += version === '0.0.0' ? '' : `_${version.replaceAll('.', '_')}`;
        state.envPath = envPath;
        
        bus.$emit(app['FIREBASE_CONFIG_CHANGED_EVENT']);
    },
    SET_ENVIRONMENTS(state, envs) {
        state.environments = envs;
    },
    COPY_CONFIGS(state, { sourceName, destinationName }) {
        ConfigFieldsToCopy.forEach(fieldName => {
            Vue.set(state.config, fieldName + destinationName, state.config[fieldName + sourceName]);
        });
    },
    
    SAVE_CURRENCY(state, currencyData) {
        const currencies = clone(state.config[`Currencies${state.environment}`]);
        const currencyIndex = currencies.findIndex(currency => currency.id === currencyData.id);
        
        if (currencyIndex > -1)
            currencies[currencyIndex] = currencyData;
        else
            currencies.push(currencyData);
        
        Vue.set(state.config, `Currencies${state.environment}`, currencies);
    },
    SAVE_CATEGORY(state, categoryData) {
        const categories = state.config?.[`Stories${state.envPath}`]?.categories;
        const categoryIndex = categories.findIndex(category => category.id === categoryData.id);
    
        if (categoryIndex > -1)
            categories[categoryIndex].name = categoryData.name;
        else {
            categoryData.stories = [];
            categories.push(categoryData);
        }
    
        Vue.set(state.config[`Stories${state.envPath}`], 'categories', categories);
    
    },
    SAVE_GENRE(state, data) {
        /*const genres = clone(state.config?.[`CatalogModule${state.envPath}`].Genres);
    
        if (!genres[data.id]) genres[data.id] = {};
    
        genres[data.id].Name = data.name;
        Vue.set(state.config[`CatalogModule${state.envPath}`], 'Genres', genres);*/
    },
    SAVE_PRICE(state, priceData) {
        const prices = clone(state.config[`Prices${state.environment}`].prices);
        const priceIndex = prices.findIndex(price => price.id === priceData.id);
        if (priceIndex > -1) prices[priceIndex] = priceData;
        else prices.push(priceData);
        Vue.set(state.config[`Prices${state.environment}`], 'prices', prices);
    },
    UPDATE_ECONOMY_CONFIG(state, data) {
        Object.keys(data).forEach(key => Vue.set(state.config[`EconomyConfig${state.environment}`], key, data[key]));
    },
    UPDATE_DAILY_BONUSES_CONFIG(state, config) {
        const dailyBonuses = clone(state.config[`DailyBonuses${state.envPath}`]);
        Object.keys(config).forEach(key => dailyBonuses[key] = config[key]);
        Vue.set(state.config, `DailyBonuses${state.envPath}`, dailyBonuses);
    },
    SET_DEFAULT_USER_CONFIG(state, config) {
        Vue.set(state.config, `Users${state.envPath}`, config);
    },
    UPDATE_DEFAULT_USER_EVENT_BONUSES(state, { eventName, stageName, property, value }) {
        Vue.set(state.config[`Users${state.envPath}`]?.[eventName]?.Bonuses?.[stageName], property, value);
    },
    UPDATE_DEFAULT_USER_EVENT(state, { eventName, property, value }) {
        Vue.set(state.config[`Users${state.envPath}`]?.[eventName], property, value);
    },
    SET_APP_MODULE_CONFIG(state, config) {
        Vue.set(state.config, `AppModule${state.envPath}`, config);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
