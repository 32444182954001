import Vue from 'vue';

/**
 * Мутация, которая обновляет перечисляемые состояния по key => value.
 *
 * @param state
 * @param data
 * @constructor
 */
export const UPDATE = (state, data) => {
    Object.entries(data).forEach(([k, v]) => {
        Vue.set(state, k, v);
    });
};

/**
 * Экшен для вызова мутации обновления состояний по key => value.
 *
 * @param commit
 * @param data
 * @returns {*}
 */
export const update = ({ commit }, data) => commit('UPDATE', data);
