import currencies from '@/configs/currencies';

export default {
	getConfigEnvs: state => Object.keys(state.environments),
	stories: state => state.config?.[`Stories${state.envPath}`]?.stories || [],
	categories: state => state.config?.[`Stories${state.envPath}`]?.categories || [],
	
	products: state => state.config?.[`Products${state.envPath}`]?.products || [],
	prices: state => state.config?.[`Prices${state.envPath}`]?.prices || [],
	economyConfig: state => state.config?.[`EconomyConfig${state.envPath}`] || [],
	dailyBonuses: state => state.config?.[`DailyBonuses${state.envPath}`] || [],
	currencies: _ => Object.values(currencies).map((name, id) => ({id: id + 1, name})),
	appModule: state => state.config?.[`AppModule${state.envPath}`] || {}
};
