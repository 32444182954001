import {clone, sortDesc} from '@/utils';
import Vue from 'vue';

const getters = {
    getNewProductID: state => {
        const idsDesc = state.config[`Products${state.environment}`].products.map(product => product.id).sort(sortDesc);
        return idsDesc[0] + 1;
    },
};
const actions = {
    async saveProduct({commit, dispatch}, productData) {
        return new Promise(resolve => {
            commit('SAVE_PRODUCT', productData);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
    async deleteProduct({commit, dispatch}, productId) {
        return new Promise(resolve => {
            commit('DELETE_PRODUCT', productId);
            dispatch('updateConfig')
                .then(response => resolve(response))
                .catch(error => resolve(error));
        });
    },
};
const mutations = {
    SAVE_PRODUCT(state, productData) {
        const products = clone(state.config[`Products${state.envPath}`].products);
        const productIndex = products.findIndex(product => product.id === productData.id);
        if (productIndex > -1) products[productIndex] = productData;
        else products.push(productData);
        Vue.set(state.config[`Products${state.envPath}`], 'products', products);
    },
    DELETE_PRODUCT(state, productId) {
        const products = clone(state.config[`Products${state.envPath}`].products);
        const productIndex = products.findIndex(product => product.id === productId);
        if (productIndex === -1) return;
        products.splice(productIndex, 1);
        Vue.set(state.config[`Products${state.envPath}`], 'products', products);
    },
};

export default {
    getters,
    actions,
    mutations
}
